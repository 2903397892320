import React from "react";
import { 
  Seo, 
  Link, 
  Section, 
  Container, 
} from "@components";
import { PageLayout } from "../../components/site/layout/page-layout";
import { RightSidebar } from "../../components/site/product";

import { StaticImage } from "gatsby-plugin-image";

const MobilityMarketPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      {/* Title Text start */}
      <div className="bg-[#122331] w-full">
        <Section>
          <Container className="py-[100px] md:py-[30px]">
            <div className="md:flex justify-between items-center">
              <div className="w-full md:w-[450px]">
                <h1 className="w-fit m-auto text-[40px] leading-[50px] md:ml-0 md:text-[53px] md:leading-[70px] font-semibold text-center text-[#ffffff] border-b-[6px] border-[#B21C7A] pb-[18px]">
                  Industries
                </h1>
              </div>
              <div className="w-full md:w-1/2 mt-[58px] md:mt-0">
                <StaticImage
                  src={`../../components/site/markets/images/industries.png`}
                  className="w-full md:max-w-[593px] h-auto"
                  alt="Mobile App and API Protection | Approov"
                  objectFit="contain"
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>
      {/* Title Text end */}
      <Section>
        <Container className="pt-[20px] pb-[50px] md:pt-[60px] md:pb-[70px] md:flex justify-between">
          <div className="md:w-full md:mr-[38px]">
            <h6 className="text-[#bd2a7b] font-bold text-[30px] my-0">RETAIL</h6>

            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Convenience Without Compromise
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  Shoppers demand convenience and quick results — they don't log on to just browse, they balk at
                  re-entering personal details and passwords, and they won’t complete annoying captcha challenges.
                  However, providing convenience to users does not have to be at the expense of solid security.
                  Approov Mobile App Protection secures API connections while maintaining a positive user experience
                  by preventing:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Product & price scraping
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        APIs open to customer browsing are open to scraping tools as well.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Prevents pricing bots from scraping your catalog and undercutting your competitive position.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Denial of Inventory
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Bots can also tie up your available inventory by creating fake accounts and saving items in carts
                        with no intent to purchase.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Blocks hackers from reverse engineering and using your APIs to tie up inventory and frustrate your shoppers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] lg:flex justify-between">
              <StaticImage
                src={`../../components/site/images/retails-scaffold.png`}
                className="w-full lg:max-w-[400px] h-[365px] h-auto rounded-[10px]"
                alt="Rear view of a food delivery rider on a bicycle"
                objectFit="cover"
              />
              <div className="w-full lg:ml-[35px] pt-[20px]">
                <div className="lg:text-[17px] lg:leading-[29px] text-black">
                Learn how Scoffable protects against data scraping and denial of service.
                </div>
                <Link href="/customer/scoffable" className="!no-underline">
                  <div className="text-white bg-[#78A65A] mt-[25px] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center md:w-fit transition hover:bg-[#1b7609]">
                    Scoffable's Customer Story
                  </div>
                </Link>
              </div>
            </div>
            
            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Build a Trusted Buying Experience
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  Customers shop at places they trust. When what you offer is limited, time-sensitive, or popular,
                  creating a fair system for purchasing means creating a secure system accessible only by real
                  customers. Approov helps maintain customer trust by preventing bots from:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Ticket Scalping
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        <Link className="!underline" href="https://approov.io/blog/mobile-auction-apps-scalping-and-sniping">Scalping bots</Link> automatically buy and later resell, crowding out your real customers from purchasing
                        new tickets or limited product releases.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Locks out scalping bots so that regardless of user credentials, purchasing can only be done from
                        your genuine mobile app.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Auction Sniping
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        <Link className="!underline" href="https://approov.io/blog/mobile-auction-apps-scalping-and-sniping">Sniping bots</Link> place last second bids in online auctions, locking out good faith participants.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Requires bids go through your mobile app to block sniping bots from undermining human bidders.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] lg:flex justify-between lg:flex-row-reverse">
              <StaticImage
                src={`../../components/site/images/retails-second..png`}
                className="w-full lg:max-w-[400px] h-[365px] h-auto rounded-[10px]"
                alt="abandoned shopping carts and clothing set on fire"
                objectFit="cover"
              />
              <div className="w-full lg:ml-[35px] pt-[20px]">
                <div className="lg:text-[17px] lg:leading-[29px] text-black">
                  17% of online shoppers have abandoned an order during checkout due to security concerns.
                </div>
              </div>
            </div>
            
            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Protect your Reputation
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  For retailers, reputation is everything. With any hint of data breaches, exposure of private
                  information, misuse of purchase history, or theft of credit card numbers, shoppers will abandon
                  your store or service forever. Approov can prevent data breaches by blocking attacks such as:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Account Takeover
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        <Link className="!underline" href="/mobile-app-security/rasp/api-security/">Credential stuffing attacks</Link> use credentials stolen from other site breaches or they repeatedly try
                        weak credential combinations to take over your customer accounts. Today, this likely makes up the
                        majority of your login traffic.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Frustrates brute force attacks by only accepting login requests coming from your legitimate
                        shopping app.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Gift Card Cracking
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Gift cards with valid balances can be identified and then used or sold without requiring login
                        credentials.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex justify-center">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Requires that gift cards be applied through your mobile app to protect your gift card value from
                        brute force crackers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[50px] md:mt-0 md:max-w-[280px]">
            <RightSidebar />
          </div>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default MobilityMarketPage;
